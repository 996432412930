<template>
  <b-container class="import-rule-wizard bg-light page-wrapper">
    <b-row class="main-content-wrapper" no-gutters>
      <b-col>
        <b-breadcrumb :items="breadcrumbItems" class="pl-1 dark"></b-breadcrumb>
        <h1 v-if="!isUpdate" class="text-left">{{$t(translationPath+'common.title_create')}} {{ h1ImportRuleTitle }}</h1>
        <h1 v-if="isUpdate" class="text-left">{{$t(translationPath+'common.title_edit')}} {{ h1ImportRuleTitle }}</h1>
        <div class="step-container-v2">
          <div class="stepper bg-light mx-auto text-regular">
            <form-wizard @on-complete="handleSubmit" :stepSize="'xs'" :color="'#ffffff'"  :errorColor="'#ffffff'" ref="createTemplate" v-bind:backButtonText="$t('common.previous')" v-bind:nextButtonText="$t('common.next')" v-bind:finishButtonText="$t('common.finish')">
                <tab-content v-bind:title="$t(translationPath + 'step1.title')" :before-change="()=>validateStep('template1')">
                    <import-rule-wizard-step01 ref="template1" :isUpdate="isUpdate" :importRuleData="templateData" @on-account-selected="onAccountSelected" @on-validate="mergeTemplateData"></import-rule-wizard-step01>
                </tab-content>
                <tab-content v-bind:title="$t(translationPath + 'step2.title')" :before-change="()=>validateStep('template2')">
                    <import-rule-wizard-step02 ref="template2" :isUpdate="isUpdate" @on-validate="mergeTemplateData" :importRuleData="templateData"></import-rule-wizard-step02>
                </tab-content>
                <tab-content v-bind:title="$t(translationPath + 'step3.title')" :before-change="()=>validateStep('template3')">
                    <import-rule-wizard-step03 ref="template3" :isUpdate="isUpdate" :importRuleData="templateData" @on-validate="mergeTemplateData"></import-rule-wizard-step03>
                </tab-content>
            </form-wizard>
            <success-modal v-if="!isUpdate"
              :title="$t(translationPath + 'success_modal.title_template')"
              :message="$t(translationPath + 'success_modal.title_template')"
              :button="$t(translationPath + 'success_modal.button_template_overview')"
              :redirect="'/transactions/import/rules'"
              :orLink="'/redirect?to=importRulesCreate'"
              :orLinkText="$t(translationPath + 'success_modal.or_create_template')"
              @hide="$router.push('/redirect?to=importRulesCreate')"
            ></success-modal>
            <success-modal v-if="isUpdate"
              :title="$t(translationPath + 'success_modal.title_template_update')"
              :message="$t(translationPath + 'success_modal.title_template')"
              :button="$t(translationPath + 'success_modal.button_template_overview')"
              :redirect="'/transactions/import/rules'"
              :orLink="'/transactions/import/rules/create'"
              :orLinkText="$t(translationPath + 'success_modal.or_create_template')"
              @hide="$router.push('/transactions/import/rules/create')"
            ></success-modal>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ImportRuleWizardStep01 from './ImportRuleWizardStep01'
import ImportRuleWizardStep02 from './ImportRuleWizardStep02'
import ImportRuleWizardStep03 from './ImportRuleWizardStep03'
import TitleMixins from '@/mixins/title'
import axios from 'axios'
import SuccessModal from '@/components/modals/SuccessModal'
export default {
  name: 'ImportRuleWizard',
  mixins: [TitleMixins],
  components: {
    SuccessModal,
    FormWizard,
    TabContent,
    ImportRuleWizardStep01,
    ImportRuleWizardStep02,
    ImportRuleWizardStep03
  },
  data () {
    return {
      h1ImportRuleTitle: null,
      templateData: {},
      translationPath: 'transactions.import.import_rule_wizard.',
      isUpdate: false
    }
  },
  computed: {
    breadcrumbItems () {
      return [
        { text: this.$t('common.header.header_menu.transactions.transactions'), to: '/transactions/view' },
        { text: this.$t('transactions.submenu.import_rules'), to: '/transactions/import/rules' },
        { text: '', to: '', active: true }
      ]
    },
    titleMeta () {
      return this.isUpdate ? this.$t(this.translationPath + 'common.title_edit') : this.$t(this.translationPath + 'common.title_create')
    }
  },
  methods: {
    validateStep (name) {
      return this.$refs[name].validate()
    },
    mergeTemplateData (stepData) {
      this.templateData = Object.assign({}, this.templateData, stepData)
    },
    onAccountSelected (account) {
      this.h1ImportRuleTitle = account ? '"' + account.title + '"' : null
    },
    async handleSubmit () {
      const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/bankstatements/import/rules' + (this.isUpdate ? '/' + this.$route.params.rule_id : '')
      const sApiMethod = this.isUpdate ? 'put' : 'post'
      await axios({
        method: sApiMethod,
        url: sApiEndpoint,
        data: this.templateData
      })
        .then(data => {
          this.$bvModal.show('successModal')
        })
        .catch(error => {
          console.error(error)
        })
    }
  },
  async created () {
    if (this.$route.name === 'ie-rules-edit') {
      this.isUpdate = true
      var self = this
      await axios.get(`${process.env.VUE_APP_ROOT_API}/bankstatements/import/rules/` + this.$route.params.rule_id)
        .then(function (response) {
          const data = response.data.data
          Object.assign(self.templateData, data)
          self.mergeTemplateData({})
        })
        .catch(function (error) {
          console.error(error)
        })
    }
  },
  watch: {
    templateData () {
      if (this.templateData.account_title) {
        this.h1ImportRuleTitle = '"' + this.templateData.account_title + '"'
      }
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/form-wizard-2.scss';
</style>
