<template>
  <b-container class="template-wizard-step03">
    <b-row no-gutter>
      <b-col cols="12" lg="5" class="detail-text text-left py-3 px-3 py-lg-5 px-lg-5">
        <h2 class="text-dark">
          {{$t(translation_path+'subheading')}}
        </h2>
        <p class="text-gray">
          {{$t(translation_path+'intro_p1')}}
        </p>
      </b-col>
      <b-col cols="12" lg="7" class="detail-form text-left py-3 py-lg-5 pl-3 pl-lg-5">
        <b-row class="mt-2">
          <b-col class="text-left">
            <strong>{{ $t( translation_path + 'labels.title' ) }}</strong>
            <b-form-group :state="titleState" :invalid-feedback="invalidTitleFeed">
              <b-form-input v-model="title" :state="titleState"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col class="text-left">
            <strong>{{ $t( translation_path + 'labels.status' ) }}</strong>
            <b-form-group :state="activeState" :invalid-feedback="invalidActiveFeed">
              <b-form-select v-model="is_active" :options="statusOptions" :state="activeState"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col class="text-left">
            <strong>{{ $t( translation_path + 'labels.description' ) }}</strong>
            <b-form-textarea
              id="textarea"
              v-model="template_description"
              :placeholder="$t(translation_path+'placeholders.description')"
              rows="4"
              max-rows="4"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'

export default {
  name: 'ImportRuleWizardStep03',
  props: ['importRuleData', 'isUpdate'],
  data () {
    return {
      template_description: null,
      title: null,
      is_active: 1,
      translation_path: 'transactions.import.import_rule_wizard.step3.'
    }
  },
  validations: {
    title: { required, maxLength: maxLength(255) },
    is_active: { required },
    form: ['title', 'is_active']
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    intro_p2_path () {
      return this.translation_path + 'intro_p2'
    },
    statusOptions () {
      const translationPathStatusSelect = this.translation_path + 'selects.status.'
      return [
        { value: 1, text: this.$t(translationPathStatusSelect + 'yes') },
        { value: 0, text: this.$t(translationPathStatusSelect + 'no') }
      ]
    },
    activeState () {
      return this.$v.is_active.$anyError ? !this.$v.is_active.$error : null
    },
    titleState () {
      return this.$v.title.$anyError ? !this.$v.title.$error : null
    },
    invalidActiveFeed () {
      if (this.$v.is_active.$error) {
        return this.$i18n.t(this.translation_path + 'errors.status_required')
      } else {
        return ''
      }
    },
    invalidTitleFeed () {
      if (this.$v.title.required === false) { return this.$t(this.translation_path + 'errors.title_required') }
      if (this.$v.title.maxLength === false) { return this.$t(this.translation_path + 'errors.title_maxlength') }
      return ''
    }
  },
  methods: {
    validate () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const templateCondition = {
          description: this.template_description,
          is_active: this.is_active,
          rule_title: this.title
        }
        this.$emit('on-validate', templateCondition)
      }
      return !this.$v.$invalid
    }
  },
  watch: {
    importRuleData () {
      this.template_description = Object.prototype.hasOwnProperty.call(this.importRuleData, 'description') && this.importRuleData.description ? this.importRuleData.description : null
      this.title = Object.prototype.hasOwnProperty.call(this.importRuleData, 'rule_title') && this.importRuleData.rule_title ? this.importRuleData.rule_title : null
      this.is_active = Object.prototype.hasOwnProperty.call(this.importRuleData, 'is_active') ? (this.importRuleData.is_active ? 1 : 0) : 1
    }
  }
}
</script>

<style lang="scss">
.template-wizard-step03 {
    .detail-form{
        border-left:1px solid #D7D7D7;
        .custom-select{
            height:46px;
        }
    }
}
</style>
