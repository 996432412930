<template>
  <b-container class="template-wizard-step02">
    <b-row no-gutter>
      <b-col cols="12" lg="5" class="detail-text text-left py-3 px-3 py-lg-5 px-lg-5">
        <h2 class="text-dark">
          {{$t(translation_path+'subheading')}}
        </h2>
        <p class="text-gray">
          {{$t(translation_path+'intro_text')}}
        </p>
      </b-col>
      <b-col cols="12" lg="7" class="detail-form text-left py-3 py-lg-5 pl-3 pl-lg-5">
        <b-row>
          <b-col>
            <h2>{{$t(translation_path+'labels.rule')}}</h2>
            <b-alert show variant="info">
              <i18n path="transactions.import.import_rules.rule_description" tag="span">
                <strong slot="title_operator">{{translateOperator(importRuleData.title_operator)}}</strong>
                <strong slot="title_value">{{ importRuleData.title_value }}</strong>
                <strong slot="transaction_type">{{ $t(  'common.transaction_types.' + importRuleData.transaction_type) }}</strong>
                <strong slot="amount_operator">{{translateOperator(importRuleData.amount_operator)}}</strong>
                <strong slot="amount_value" v-if="importRuleData.amount_operator!=='is_any'">{{importRuleData.amount_value | formatAmount( currentCOA.locale, currentCOA.currency ) }}</strong>
                <strong slot="amount_value" v-else>'*'</strong>
                <b-link slot="account" class="text-black" :to="'/reports/performance/accounts/'+importRuleData.account_id+'/view'"><strong>{{ importRuleData.account_title }}</strong></b-link>
                <strong slot="percentage">{{ importRuleData.percentage }}</strong>
              </i18n>

            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="pb-3">
            <hr />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-3 pl-0 pr-0">
            <b-alert
              :show="testSubmitted && testStatus ? dismissCountDown:0"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
            >

              <h3>{{$t(translation_path+'messages.success_title')}}</h3>
              <p class="mb-0">
                {{$t(translation_path+'messages.success_description')}}
              </p>
            </b-alert>
            <b-alert
              :show="testSubmitted && !testStatus ? dismissCountDown:0"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
            >

              <h3>{{$t(translation_path+'messages.error_title')}}</h3>
              <p class="mb-0">
                {{$t(translation_path+'messages.error_description')}}
              </p>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3" class="text-left text-md-right" align-self="center">
            <strong>{{$t(translation_path+'labels.test_title')}}</strong>
          </b-col>
          <b-col class="pr-0 mt-2 mt-md-0">
            <b-form-group class="mb-0" :state="testTitleState" :invalid-feedback="testTitleInvalidFeed">
              <b-form-input type="text" v-model="test_title" :placeholder="$t(translation_path+'placeholders.test_title')" :state="testTitleState" @input="$v.test_title.$touch()"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="3" class="text-left text-md-right" align-self="center">
            <strong>{{$t(translation_path+'labels.test_amount')}}</strong>
          </b-col>
          <b-col class="pr-0 mt-2 mt-md-0">
            <b-form-group class="mb-0" :state="testAmountState" :invalid-feedback="testAmountInvalidFeed">
              <b-form-input type="number" v-model="test_amount" :placeholder="$t(translation_path+'placeholders.test_amount')" :state="testAmountState" @input="$v.test_amount.$touch()"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="3" class="text-left text-md-right" align-self="center">
            <strong>{{$t(translation_path+'labels.test_transaction_type')}}</strong>
          </b-col>
          <b-col class="pr-0 mt-2 mt-md-0">
            <b-form-group class="mb-0" :state="testAmountState" :invalid-feedback="testTransactionTypeInvalidFeed">
              <b-form-select :options="transactionTypes" v-model="test_transaction_type" @input="$v.test_transaction_type.$touch()" :state="transactionTypeOperatorState"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="pr-0 text-right mt-2">
            <b-button class="btn btn-secondary btn-sm testButton" v-on:click="testTemplate">{{ $t(translation_path+'labels.test_rule') }}</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'

export default {
  name: 'ImportRuleWizardStep02',
  props: ['importRuleData', 'isUpdate'],
  data () {
    return {
      test_title: null,
      test_amount: null,
      test_transaction_type: null,
      testSubmitted: false,
      testStatus: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      translation_path: 'transactions.import.import_rule_wizard.step2.',
      translationPathOperators: 'transactions.import._common.operators.'
    }
  },
  validations: {
    test_title: {
      required
    },
    test_amount: {
      required
    },
    test_transaction_type: {
      required
    },
    form: ['test_title', 'test_amount', 'test_transaction_type']
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    testTitleState () {
      return this.$v.test_title.$anyError ? !this.$v.test_title.$error : null
    },
    testTitleInvalidFeed () {
      if (this.$v.test_title.$error) {
        return this.$t(this.translation_path + 'error_msg_title_required')
      } else {
        return ''
      }
    },
    transactionTypeOperatorState () {
      return this.$v.test_transaction_type.$anyError ? !this.$v.test_transaction_type.$error : null
    },
    testTransactionTypeInvalidFeed () {
      if (this.$v.test_transaction_type.$error) {
        return this.$t(this.translation_path + 'error_msg_title_required')
      } else {
        return ''
      }
    },
    testAmountState () {
      return this.$v.test_amount.$anyError ? !this.$v.test_amount.$error : null
    },
    testAmountInvalidFeed () {
      if (this.$v.test_amount.$error) {
        return this.$t(this.translation_path + 'error_msg_amount_required')
      } else {
        return ''
      }
    },
    operatorTranslations () {
      return [
        { value: 'equals', text: this.$t(this.translationPathOperators + 'equals') },
        { value: 'contains', text: this.$t(this.translationPathOperators + 'contains') },
        { value: 'starts_with', text: this.$t(this.translationPathOperators + 'starts_with') },
        { value: 'ends_with', text: this.$t(this.translationPathOperators + 'ends_with') },
        { value: 'less_than', text: this.$t(this.translationPathOperators + 'less_than') },
        { value: 'greater_than', text: this.$t(this.translationPathOperators + 'greater_than') },
        { value: 'between', text: this.$t(this.translationPathOperators + 'between') },
        { value: 'is_any', text: this.$t(this.translationPathOperators + 'is_any') },
        { value: 'regex', text: this.$t(this.translationPathOperators + 'regex') }
      ]
    },
    transactionTypes () {
      return [
        { value: '', text: '' },
        { value: 'income', text: this.$t('common.transaction_types.income') },
        { value: 'expense', text: this.$t('common.transaction_types.expense') }
      ]
    }
  },
  methods: {
    testTemplate () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const testData = Object.assign({}, this.importRuleData, { test_title: this.test_title, test_amount: this.test_amount, test_transaction_type: this.test_transaction_type })
        this.testSubmitted = true
        axios.post(`${process.env.VUE_APP_ROOT_API}/bankstatements/import/rules/test`, testData)
          .then(response => {
            this.dismissCountDown = this.dismissSecs
            this.testStatus = response.data.data.match_found
          })
          .catch(err => {
            console.error(err)
          })
      }
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    translateOperator (value) {
      const selectedOperator = (this.operatorTranslations).filter(item => item.value === value)
      return selectedOperator.length > 0 ? selectedOperator[0].text : value
    },
    validate () {
      return true
    }
  },
  filters: {
    formatAmount
  }
}
</script>

<style lang="scss">
.template-wizard-step02 {
  .detail-form{
    border-left:1px solid #D7D7D7;
    .custom-select{
      height:46px;
    }
  }
  .testButton
  {
    font-size:90%;
    padding:5px 15px 5px 15px;
  }
}
</style>
