<template>
    <b-container class="template-wizard-step01">
        <b-row no-gutter>
            <b-col cols="12" lg="5" class="detail-text text-left py-3 px-3 py-lg-5 px-lg-5">
                <h2 class="text-dark">
                    {{$t(translation_path+'subheading')}}
                </h2>
                <p class="text-gray">
                    {{$t(translation_path+'intro_p1')}}
                </p>
              <i18n :path="intro_p2_path" tag="p" class="text-gray">
                <b-link slot="help_pages" target="_blank" href="https://forum.spirecta.se/t/importregler-din-hjalp-att-spara-tid-vid-import/139">{{ $t('common.help_pages') }}</b-link>
              </i18n>
            </b-col>
            <b-col cols="12" lg="7" class="detail-form text-left py-3 py-lg-5 pl-3 pl-lg-5" align-self="center">
                <b-row>
                    <b-col md="3" class="text-left text-md-right align-self-center">
                        <strong>{{$t(translation_path+'labels.title')}}</strong>
                    </b-col>
                    <b-col class="pr-0 mt-2 mt-md-0">
                      <b-form-group label-for="amount" class="mb-0" :state="titleOperatorState" :invalid-feedback="titleOperatorInvalidFeedback" label-class="align-self-center">
                        <b-form-select :options="titleOperators" v-model="title_operator" @input="titleOperatorInputHandler" :state="titleOperatorState"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col class="pr-0 mt-2 mt-md-0">
                      <b-form-group class="mb-0" :state="titleInputState" :invalid-feedback="titleInputFeedback" label-class="align-self-center">
                        <b-form-input type="text"
                                      v-model="title_value"
                                      :disabled="titleDisabled"
                                      :state="titleInputState"
                                      :placeholder="$t(translation_path + 'placeholders.title')"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col md="3" class="text-left text-md-right" align-self="center">
                        <strong>{{$t(translation_path+'labels.amount')}}</strong>
                    </b-col>
                    <b-col class="pr-0 mt-2 mt-md-0">
                      <b-form-group class="mb-0" :state="amountOperatorState" :invalid-feedback="amountOperatorInvalidFeedback">
                        <b-form-select :options="amountOperators" v-model="amount_operator" @input="amountOperatorInputHandler" :state="amountOperatorState"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col class="pr-0 mt-2 mt-md-0">
                        <b-form-group :state="amountValueState" :invalid-feedback="invalidAmountValueFeedback" class="mb-0" label-class="align-self-center">
                            <currency-input
                              @input="$v.amount_value.$touch()"
                              :state="amountValueState"
                              :disabled="amountDisabled"
                              :placeholder="$t(translation_path + 'placeholders.amount')"
                              v-model="amount_value"
                              :input-class="{'is-invalid': amountValueState === false}"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <hr />
                  </b-col>
                </b-row>
              <b-row class="mt-2">
                <b-col md="3" class="text-left text-md-right" align-self="center">
                  <strong>{{ $t( translation_path + 'labels.transaction_type' ) }}</strong>
                </b-col>
                <b-col class="pr-0 mt-2 mt-md-0">
                  <b-form-group class="mb-0" :state="transactionTypeState" :invalid-feedback="transactionTypeInvalidFeedback" label-class="align-self-center">
                    <b-form-select :options="transactionTypeOptions" v-model="transaction_type" :state="transactionTypeState"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
                <b-row class="mt-2">
                    <b-col md="3" class="text-left text-md-right" align-self="center">
                        <strong>{{ $t( translation_path + ( currentUser.strict_accounting_mode ? 'labels.account' : 'labels.category') ) }}</strong>
                    </b-col>
                    <b-col class="pr-0 mt-2 mt-md-0">
                      <b-form-group class="mb-0" label-class="align-self-center">
                        <custom-select
                          v-model="account_id"
                          :options="accountsOptionArray"
                          grouping-values="accounts"
                          grouping-label="title"
                          label="title"
                          :loading="loadingAccounts"
                          :placeholder="currentUser.strict_accounting_mode ? $t('common.select_account') : $t('common.select_category')"
                          :noResultText="currentUser.strict_accounting_mode ? $t('common.no_account_found') : $t('common.no_category_found')"
                          :search-placeholder="$t('common.type_to_filter')"
                        >
                        </custom-select>
                      </b-form-group>
                    </b-col>
                </b-row>
              <b-row class="mt-2" v-if="!hidePercentage">
                <b-col md="3" class="text-left text-md-right" align-self="center">
                  <strong>{{$t(translation_path+'labels.percentage')}}</strong>
                </b-col>
                <b-col class="pr-0">
                  <b-form-group
                    label-align-lg="right"
                    class="pt-lg-4"
                    :invalid-feedback="invalidAccountPercentageFeedback"
                    :state="!$v.account_percentage.$error">

                    <template slot="description">{{ $t(translation_path + 'labels.percentage_help_text' , {active: account_percentage, passive: passivePercentageValue })}}</template>

                    <b-col sm="12" md="9" lg="9" xl="10" class="float-left pl-0 pb-2">
                      <b-form-input id="account_percentage" class="slider"
                                    type="range" min="0" max="100" step="5"
                                    v-model="account_percentage"
                                    @input="$v.account_percentage.$touch()"
                      ></b-form-input>
                    </b-col>
                    <b-col sm="12" md="3" lg="3" xl="2" class="float-left mb-2 pl-0 pr-0">
                      <percentage-input
                        inputId="account_percentage"
                        v-model="account_percentage"
                        @input="$v.account_percentage.$touch()"
                      />
                    </b-col>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
import { mapState } from 'vuex'
import CustomSelect from '@/components/common/CustomSelect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import CurrencyInput from '@/components/common/CurrencyInput'
import PercentageInput from '@/components/common/PercentageInput'

const validAmountValue = (value, vm) => {
  if (vm.amountDisabled) {
    return true
  } else {
    if (value === '') {
      return false
    }
    const reg = /(\*|(\d+((,|\.)\d\d)?(:\d+((,|\.)\d\d)?)?))/g
    if (!reg.test(value)) {
      return false
    }
    if (vm.amount_operator === 'less_than' && value < 0) { return false }
    if (vm.amount_operator === 'greater_than' && value < 0) { return false }
    if (vm.amount_operator === 'equals' && value < 0) { return false }

    return true
  }
}

export default {
  name: 'ImportRuleWizardStep01',
  props: ['importRuleData', 'isUpdate'],
  components: { CustomSelect, CurrencyInput, PercentageInput },
  data () {
    return {
      title_operator: null,
      title_value: null,
      amount_operator: null,
      amount_value: null,
      transaction_type: null,
      account_id: null,
      translation_path: 'transactions.import.import_rule_wizard.step1.',
      translationPathOperators: 'transactions.import._common.operators.',
      accountsOptions: [],
      accountsOptionArray: [],
      account_percentage: 50,
      amountDisabled: false,
      titleDisabled: false,
      enableMultipleAccount: false,
      loadingAccounts: false,
      loadingRules: false,
      disableTitleOperatorInputHandler: false,
      disableAmountOperatorInputHandler: false
    }
  },
  validations: {
    title_operator: { required },
    title_value: { required },
    amount_operator: { required },
    amount_value: { validAmountValue },
    transaction_type: { required },
    // account_id: { required },
    account_percentage: { required },
    // form: ['title_operator', 'title_value', 'amount_operator', 'amount_value', 'account_id']
    form: ['title_operator', 'title_value', 'amount_operator', 'amount_value']
  },
  created () {
    if (this.isUpdate) {
      this.disableTitleOperatorInputHandler = true
      this.disableAmountOperatorInputHandler = true
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    passivePercentageValue () {
      return 100 - this.account_percentage
    },
    intro_p2_path () {
      return this.translation_path + 'intro_p2'
    },
    transactionTypeOptions () {
      return [
        { value: null, text: '' },
        { value: 'income', text: this.$t('common.transaction_types.income') },
        { value: 'expense', text: this.$t('common.transaction_types.expense') },
        { value: 'transfer_unknown', text: this.$t(this.translation_path + 'transfer_types.transfer_unknown_example') },
        { value: 'transfer_to', text: this.$t(this.translation_path + 'transfer_types.transfer_to_example') },
        { value: 'transfer_from', text: this.$t(this.translation_path + 'transfer_types.transfer_from_example') }
      ]
    },
    titleOperators () {
      return [
        { value: null, text: '' },
        { value: 'equals', text: this.$t(this.translationPathOperators + 'equals') },
        { value: 'contains', text: this.$t(this.translationPathOperators + 'contains') },
        { value: 'starts_with', text: this.$t(this.translationPathOperators + 'starts_with') },
        { value: 'ends_with', text: this.$t(this.translationPathOperators + 'ends_with') },
        { value: 'is_any', text: this.$t(this.translationPathOperators + 'is_any') },
        { value: 'regex', text: this.$t(this.translationPathOperators + 'regex') }
      ]
    },
    amountOperators () {
      return [
        { value: null, text: '' },
        { value: 'equals', text: this.$t(this.translationPathOperators + 'equals') },
        { value: 'less_than', text: this.$t(this.translationPathOperators + 'less_than') },
        { value: 'greater_than', text: this.$t(this.translationPathOperators + 'greater_than') },
        // { value: 'between', text: this.$t(this.translationPathOperators + 'between') },
        { value: 'is_any', text: this.$t(this.translationPathOperators + 'is_any') }
      ]
    },
    titleOperatorState () {
      return this.$v.title_operator.$anyError ? !this.$v.title_operator.$error : null
    },
    transactionTypeState () {
      return this.$v.transaction_type.$anyError ? !this.$v.transaction_type.$error : null
    },
    titleOperatorInvalidFeedback () {
      if (this.$v.$anyError && this.$v.title_operator.$error) {
        return this.$i18n.t(this.translation_path + 'errors.title_option_required')
      } else {
        return ''
      }
    },
    titleInputState () {
      return this.$v.title_value.$anyError ? !this.$v.title_value.$error : null
    },
    titleInputFeedback () {
      if (this.$v.$anyError && this.$v.title_value.$error) {
        return this.$i18n.t(this.translation_path + 'errors.title_required')
      } else {
        return ''
      }
    },
    transactionTypeInvalidFeedback () {
      if (this.$v.$anyError && this.$v.transaction_type.$error) {
        return this.$i18n.t(this.translation_path + 'errors.transaction_type_required')
      } else {
        return ''
      }
    },
    amountOperatorState () {
      return this.$v.amount_operator.$anyError ? !this.$v.amount_operator.$error : null
    },
    amountOperatorInvalidFeedback () {
      if (this.$v.$anyError && this.$v.amount_operator.$error) {
        return this.$i18n.t(this.translation_path + 'errors.amount_option_required')
      } else {
        return ''
      }
    },
    invalidAccountPercentageFeedback () {
      return this.$v.account_percentage.$error ? this.$i18n.t('common.errors.numeric') : ''
    },
    amountValueState () {
      return this.$v.amount_value.$anyError ? !this.$v.amount_value.$error : null
    },
    invalidAmountValueFeedback () {
      if (!this.$v.amount_value.required) {
        return this.$i18n.t(this.translation_path + 'errors.amount_required')
      } else if (!this.$v.amount_value.validAmountValue) {
        return this.$i18n.t(this.translation_path + 'errors.amount_invalid')
      } else {
        return ''
      }
    },
    hidePercentage () {
      return this.transaction_type === 'transfer'
    }
  },
  methods: {
    fetchAccounts () {
      this.loadingAccounts = true
      let AccountType = null
      switch (this.transaction_type) {
        case 'income':
          AccountType = 'income'
          break
        case 'expense':
          AccountType = 'expense'
          break
        default:
          AccountType = 'asset,liability'
      }
      axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=${AccountType}&log=ImportRulesWizardStep01`)
        .then(response => {
          const optionArray = []
          const accountGroups = response.data.data
          Object.keys(accountGroups).forEach((key) => {
            optionArray.push({ title: this.$t('common.' + accountGroups[key].type) + ' / ' + accountGroups[key].title, accounts: accountGroups[key].accounts, rawType: accountGroups[key].type, rawTitle: accountGroups[key].title })
          })
          this.accountsOptionArray = optionArray

          /* First we should clear selected account if user changed type of accounts.
          * Then, if if we are on update wizard we should preselect account if it is in
          * received accounts list.
          */
          this.account_id = null
          if (this.isUpdate) {
            for (var prop in this.accountsOptionArray) {
              for (var item in this.accountsOptionArray[prop].accounts) {
                if (this.importRuleData.account_id === this.accountsOptionArray[prop].accounts[item].id) {
                  this.account_id = this.accountsOptionArray[prop].accounts[item]
                  this.$emit('on-account-selected', this.account_id)
                  break
                }
              }
              if (this.account_id) {
                break
              }
            }
          }

          this.loadingAccounts = false
        })
        .catch(error => {
          console.error(error)
          return []
        })
    },
    titleOperatorInputHandler (value) {
      if (this.disableTitleOperatorInputHandler) {
        this.disableTitleOperatorInputHandler = false
        return true
      }
      if (value === 'is_any') {
        this.$v.title_value.$reset()
        this.title_value = '*'
        this.titleDisabled = true
      } else {
        this.title_value = null
        this.$v.title_value.$reset()
        this.titleDisabled = false
      }
    },
    amountOperatorInputHandler (value) {
      if (this.disableAmountOperatorInputHandler) {
        this.disableAmountOperatorInputHandler = false
        return true
      }
      if (value === 'is_any') {
        this.$v.amount_value.$reset()
        this.amount_value = '*'
        this.amountDisabled = true
      } else {
        this.amount_value = null
        this.$v.amount_value.$reset()
        this.amountDisabled = false
      }
    },
    validate () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        const templateCondition = {
          title_operator: this.title_operator,
          title_value: this.title_value,
          amount_operator: this.amount_operator,
          amount_value: this.amount_value,
          account_id: this.account_id ? this.account_id.id : null,
          account_title: this.account_id ? this.account_id.title : null,
          percentage: this.account_percentage,
          transaction_type: this.transaction_type
        }
        this.$emit('on-validate', templateCondition)
      }
      return !this.$v.$invalid
    }
  },
  watch: {
    transaction_type () {
      this.fetchAccounts()
    },
    amount_value (newVal) {
      if (newVal) {
        this.amount_value = parseFloat(newVal)
      }
    },
    importRuleData () {
      this.title_operator = Object.prototype.hasOwnProperty.call(this.importRuleData, 'title_operator') && this.importRuleData.title_operator ? this.importRuleData.title_operator : null
      this.amount_operator = Object.prototype.hasOwnProperty.call(this.importRuleData, 'amount_operator') && this.importRuleData.amount_operator ? this.importRuleData.amount_operator : null
      this.title_value = Object.prototype.hasOwnProperty.call(this.importRuleData, 'title_value') && this.importRuleData.title_value ? this.importRuleData.title_value : null
      this.amount_value = Object.prototype.hasOwnProperty.call(this.importRuleData, 'amount_value') && this.importRuleData.amount_value ? this.importRuleData.amount_value : null
      this.transaction_type = Object.prototype.hasOwnProperty.call(this.importRuleData, 'transaction_type') && this.importRuleData.transaction_type ? this.importRuleData.transaction_type : null
      this.account_percentage = Object.prototype.hasOwnProperty.call(this.importRuleData, 'percentage') && this.importRuleData.percentage ? this.importRuleData.percentage : null

      if (this.title_operator === 'is_any') {
        this.titleDisabled = true
      }
      if (this.amount_operator === 'is_any') {
        this.amountDisabled = true
      }
    },
    '$i18n.locale' () {
      // Translate items in custom select if language is changed.
      this.accountsOptionArray.map(item => {
        item.title = this.$t('common.' + item.rawType) + ' / ' + item.rawTitle
      })
    }
  }
}
</script>

<style lang="scss">
.template-wizard-step01 {
    .detail-form{
        border-left:1px solid #D7D7D7;
        .custom-select {
            height:46px;
        }
        strong {
            /*line-height: 48px;*/
            display: block;
            /*height: 48px;*/
            /*vertical-align: middle;*/
        }
    }
}
</style>
